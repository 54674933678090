.row {
    display: flex;
    justify-content: space-between;

    > div {
        flex: 2;
    }
}

.orText {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba($color: #000, $alpha: .6);

    flex: 1;
}

.idInput {
    flex: 3;
}

.fetchButton {
    flex: 1;
    margin-left: 5px;
    width: 100%;
}

.predefinedCategoryButton {
    width: 100%;
    
    &:not(:first-child) {
        margin-left: 5px;
    }
}